import { render, staticRenderFns } from "./MerchantEditTabServices.vue?vue&type=template&id=da32b8c8&"
import script from "./MerchantEditTabServices.vue?vue&type=script&lang=js&"
export * from "./MerchantEditTabServices.vue?vue&type=script&lang=js&"
import style0 from "./MerchantEditTabServices.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports