var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [(
           _vm.productData.productImages && _vm.productData.productImages.length > 0
         )?_c('div',_vm._l((_vm.productData.productImages),function(image,index){return _c('b-avatar',{key:index,staticClass:"mr-1",attrs:{"src":image,"variant":"light-primary","size":"75px","rounded":""}})}),1):_c('div',[_c('b-avatar',{staticClass:"mr-1",attrs:{"variant":"light-primary","text":_vm.avatarText(_vm.productData.name),"size":"75px","rounded":""}})],1)]},proxy:true}])},[_c('div',{staticClass:"d-flex mb-1 align-items-center"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.productData.name)+" ")]),_vm._l((_vm.productData.productTags),function(item,i){return _c('b-badge',{key:i,staticClass:"ml-1",attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.deleteProduct.apply(null, arguments)}}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Удалить")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"TrashIcon"}})],1)],1)]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Название Ru","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название Ru","label-for":"titleRu"}},[_c('b-form-input',{attrs:{"id":"titleRu","state":errors.length > 0 ? false : null},model:{value:(_vm.productData.name),callback:function ($$v) {_vm.$set(_vm.productData, "name", $$v)},expression:"productData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Цена","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Цена","label-for":"price"}},[_c('b-form-input',{attrs:{"id":"price","state":errors.length > 0 ? false : null},model:{value:(_vm.productData.price),callback:function ($$v) {_vm.$set(_vm.productData, "price", $$v)},expression:"productData.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Скидка","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Скидка","label-for":"discountPrice"}},[_c('b-form-input',{attrs:{"id":"discountPrice","state":errors.length > 0 ? false : null},model:{value:(_vm.productData.discountPrice),callback:function ($$v) {_vm.$set(_vm.productData, "discountPrice", $$v)},expression:"productData.discountPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Штрих код","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Штрих код","label-for":"barcode"}},[_c('b-form-input',{attrs:{"id":"barcode","state":errors.length > 0 ? false : null},model:{value:(_vm.productData.barcode),callback:function ($$v) {_vm.$set(_vm.productData, "barcode", $$v)},expression:"productData.barcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Производитель","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Производитель","label-for":"producer"}},[_c('b-form-input',{attrs:{"id":"producer","state":errors.length > 0 ? false : null},model:{value:(_vm.productData.producer),callback:function ($$v) {_vm.$set(_vm.productData, "producer", $$v)},expression:"productData.producer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Страна производителя","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Страна производителя","label-for":"producerCountry"}},[_c('b-form-input',{attrs:{"id":"producerCountry","state":errors.length > 0 ? false : null},model:{value:(_vm.productData.producerCountry),callback:function ($$v) {_vm.$set(_vm.productData, "producerCountry", $$v)},expression:"productData.producerCountry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Описание RU","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание RU","label-for":"merch-desc-ru"}},[_c('b-form-textarea',{attrs:{"id":"merch-desc-ru","placeholder":"Описание","state":errors.length > 0 ? false : null,"rows":"7"},model:{value:(_vm.productData.descriptionRu),callback:function ($$v) {_vm.$set(_vm.productData, "descriptionRu", $$v)},expression:"productData.descriptionRu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Описание UZ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание UZ","label-for":"merch-desc-uz"}},[_c('b-form-textarea',{attrs:{"id":"merch-desc-uz","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"7"},model:{value:(_vm.productData.descriptionUz),callback:function ($$v) {_vm.$set(_vm.productData, "descriptionUz", $$v)},expression:"productData.descriptionUz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Описание EN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание EN","label-for":"merch-desc-en"}},[_c('b-form-textarea',{attrs:{"id":"merch-desc-en","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"7"},model:{value:(_vm.productData.descriptionEn),callback:function ($$v) {_vm.$set(_vm.productData, "descriptionEn", $$v)},expression:"productData.descriptionEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Короткое описание RU","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Короткое описание RU","label-for":"merch-desc-ru"}},[_c('b-form-textarea',{attrs:{"id":"merch-desc-ru","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"7"},model:{value:(_vm.productData.shortDescriptionRu),callback:function ($$v) {_vm.$set(_vm.productData, "shortDescriptionRu", $$v)},expression:"productData.shortDescriptionRu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Короткое описание UZ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Короткое описание UZ","label-for":"merch-desc-uz"}},[_c('b-form-textarea',{attrs:{"id":"merch-desc-uz","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"7"},model:{value:(_vm.productData.shortDescriptionUz),callback:function ($$v) {_vm.$set(_vm.productData, "shortDescriptionUz", $$v)},expression:"productData.shortDescriptionUz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Короткое описание EN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Короткое описание EN","label-for":"merch-desc-en"}},[_c('b-form-textarea',{attrs:{"id":"merch-desc-en","placeholder":"Описание","state":errors.length > 0 ? false : null,"rows":"7"},model:{value:(_vm.productData.shortDescriptionEn),callback:function ($$v) {_vm.$set(_vm.productData, "shortDescriptionEn", $$v)},expression:"productData.shortDescriptionEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }