<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <!-- <b-avatar
          v-for="(image, index) in productData.productImages"
          :key="index"
          :src="image"
          :variant="`light-primary`"
          size="75px"
          rounded
          class="mr-1"
        /> -->
        <div
          v-if="
            productData.productImages && productData.productImages.length > 0
          "
        >
          <b-avatar
            v-for="(image, index) in productData.productImages"
            :key="index"
            :src="image"
            :variant="`light-primary`"
            size="75px"
            class="mr-1"
            rounded
          />
        </div>
        <div v-else>
          <b-avatar
            :variant="`light-primary`"
            :text="avatarText(productData.name)"
            size="75px"
            class="mr-1"
            rounded
          />
        </div>
      </template>
      <div class="d-flex mb-1 align-items-center">
        <h4 class="mb-0">
          {{ productData.name }}
        </h4>
        <b-badge
          class="ml-1"
          variant="light-primary"
          v-for="(item, i) in productData.productTags"
          :key="i"
        >
          {{ item }}
        </b-badge>
      </div>
      <div class="d-flex flex-wrap">
        <!-- <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @change="handleFileUpload($event)"
          />
          <span class="d-none d-sm-inline">Загрузить</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button> -->
        <b-button
          @click.prevent="deleteProduct"
          variant="outline-danger"
          class=""
        >
          <span class="d-none d-sm-inline">Удалить</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- Field: Full Name -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Название Ru"
              rules="required"
            >
              <b-form-group label="Название Ru" label-for="titleRu">
                <b-form-input
                  id="titleRu"
                  :state="errors.length > 0 ? false : null"
                  v-model="productData.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Цена"
              rules="required"
            >
              <b-form-group label="Цена" label-for="price">
                <b-form-input
                  id="price"
                  :state="errors.length > 0 ? false : null"
                  v-model="productData.price"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Скидка"
              rules="required"
            >
              <b-form-group label="Скидка" label-for="discountPrice">
                <b-form-input
                  id="discountPrice"
                  :state="errors.length > 0 ? false : null"
                  v-model="productData.discountPrice"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Штрих код"
              rules="required"
            >
              <b-form-group label="Штрих код" label-for="barcode">
                <b-form-input
                  id="barcode"
                  :state="errors.length > 0 ? false : null"
                  v-model="productData.barcode"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Производитель"
              rules="required"
            >
              <b-form-group label="Производитель" label-for="producer">
                <b-form-input
                  id="producer"
                  :state="errors.length > 0 ? false : null"
                  v-model="productData.producer"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Страна производителя"
              rules="required"
            >
              <b-form-group
                label="Страна производителя"
                label-for="producerCountry"
              >
                <b-form-input
                  id="producerCountry"
                  :state="errors.length > 0 ? false : null"
                  v-model="productData.producerCountry"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Category -->
          <!-- <b-col cols="12" md="4">
          <b-form-group label="Категория" label-for="merchant-category">
            <v-select
              v-model="productData.vendorCategoryList"
              :options="getCategories.categories"
              @input="changeCategoryList"
              label="titleRu"
              input-id="merchant-category"
              placeholder="Выберите категории"
              multiple
            />
          </b-form-group>
        </b-col> -->

          <!-- Field: Status -->
          <!-- <b-col cols="12" md="4">
          <b-form-group label="Status" label-for="merchant-status">
            <v-select
              v-model="productData.vendorState"
              :options="statusOptions"
              :clearable="false"
              input-id="merchant-status"
            />
          </b-form-group>
        </b-col>
 -->
          <!-- Field: Description RU -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Описание RU"
              rules="required"
            >
              <b-form-group label="Описание RU" label-for="merch-desc-ru">
                <b-form-textarea
                  id="merch-desc-ru"
                  v-model="productData.descriptionRu"
                  placeholder="Описание"
                  :state="errors.length > 0 ? false : null"
                  rows="7"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Description UZ -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Описание UZ"
              rules="required"
            >
              <b-form-group label="Описание UZ" label-for="merch-desc-uz">
                <b-form-textarea
                  id="merch-desc-uz"
                  v-model="productData.descriptionUz"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Описание"
                  rows="7"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Description EN -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Описание EN"
              rules="required"
            >
              <b-form-group label="Описание EN" label-for="merch-desc-en">
                <b-form-textarea
                  id="merch-desc-en"
                  v-model="productData.descriptionEn"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Описание"
                  rows="7"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: ShortDescription RU -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Короткое описание RU"
              rules="required"
            >
              <b-form-group
                label="Короткое описание RU"
                label-for="merch-desc-ru"
              >
                <b-form-textarea
                  id="merch-desc-ru"
                  v-model="productData.shortDescriptionRu"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Описание"
                  rows="7"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: ShortDescription UZ -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Короткое описание UZ"
              rules="required"
            >
              <b-form-group
                label="Короткое описание UZ"
                label-for="merch-desc-uz"
              >
                <b-form-textarea
                  id="merch-desc-uz"
                  v-model="productData.shortDescriptionUz"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Описание"
                  rows="7"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: ShortDescription EN -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Короткое описание EN"
              rules="required"
            >
              <b-form-group
                label="Короткое описание EN"
                label-for="merch-desc-en"
              >
                <b-form-textarea
                  id="merch-desc-en"
                  v-model="productData.shortDescriptionEn"
                  placeholder="Описание"
                  :state="errors.length > 0 ? false : null"
                  rows="7"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12">
            <!-- Action Buttons -->
            <b-button
              @click.prevent="handleSubmit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import axiosIns from "@/libs/axios.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { avatarText } from "@core/utils/filter";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";

export default {
  components: {
    ToastificationContent,
    BBadge,
    BFormTextarea,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    productData: {
      type: Object,
      default: {},
    },
  },
  computed: {
    statusVariant() {
      const statusVariants = {
        /* eslint-disable key-spacing */
        ACTIVE: {
          color: "light-success",
          text: "Активный",
        },
        DELETED: {
          color: "light-danger",
          text: "Удаленный",
        },
        BLOCKED: {
          color: "light-warning",
          text: "Заблокированный",
        },
        SUSPENDED: {
          color: "light-secondary",
          text: "Приостановленный",
        },
        /* eslint-enable key-spacing */
      };

      return (status) => statusVariants[status];
    },
  },
  setup() {
    return {
      avatarText,
    };
  },
  data() {
    return {
      required,
      email,
      password,
      confirmed,

      file: null,
      fileUrl: "",
      categories: null,
      logo: null,
      statusOptions: ["ACTIVE", "DELETED", "BLOCKED", "SUSPENDED"],
    };
  },
  methods: {
    async deleteProduct() {
      this.$bvModal
        .msgBoxConfirm("Вы уверены что хотите удалить?", {
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: "Удалить",
          cancelTitle: "Отмена",
        })
        .then((value) => {
          if (value === true) {
            axiosIns
              .delete("v1/product/delete", {
                params: {
                  productId: this.$router.currentRoute.params.id,
                },
              })
              .then((response) => {
                if (response.status == 200) {
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: `Успешно`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: `Товар был успешно удален!`,
                      },
                    },
                    {
                      position: "top-right",
                    }
                  );
                  this.$emmit("userDeleted");
                }
              })
              .catch((error) => {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Ошибка`,
                      icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                      variant: "danger",
                      text: error.response.data.errorMessage,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
                if (error.response) {
                  // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message);
                }
              })
              .finally(() => {
                this.$router.push({ name: "products" });
              });
          }
        });
    },
    async handleFileUpload(event) {
      this.file = event.target.files[0];
      const formData = new FormData();
      formData.append("logo", this.file);
      const response = await axiosIns.post("image/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.productData.logoUrl = response.data.data.imageUrl;
    },
    async handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axiosIns
            .put("v1/product/edit", {
              id: this.productData.id,
              name: this.productData.name,
              barcode: this.productData.barcode,
              descriptionRu: this.productData.descriptionRu,
              descriptionUz: this.productData.descriptionUz,
              descriptionEn: this.productData.descriptionEn,
              shortDescriptionRu: this.productData.shortDescriptionRu,
              shortDescriptionEn: this.productData.shortDescriptionEn,
              shortDescriptionUz: this.productData.shortDescriptionUz,
              order: this.productData.order,
              price: this.productData.price,
              discountPrice: this.productData.discountPrice,
              producer: this.productData.producer,
              producerCountry: this.productData.producerCountry,
            })
            .then((response) => {
              if (response.status == 200) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Успешно`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Товар был успешно изменен!`,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
              }
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Ошибка`,
                    icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                    variant: "danger",
                    text: error.response.data.errorMessage,
                  },
                },
                {
                  position: "top-right",
                }
              );
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .finally(() => {
              this.$router.push({ name: "products" });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
