<template>
  <div>
    <div class="d-flex align-items-center mb-1">
      <a @click="$router.go(-1)" class="grey-color">
        <div class="d-flex align-items-center mr-2">
          <feather-icon icon="ArrowLeftIcon" size="20" class="mr-25" />
          <h6 class="mb-0">Назад</h6>
        </div>
      </a>
    </div>
    <h1 class="mb-2">Редактирование товара</h1>
    <component :is="productData === undefined ? 'div' : 'b-card'">
      <!-- Alert: No item found -->
      <b-alert variant="danger" :show="productData === undefined">
        <h4 class="alert-heading">Ошибка при получении данных этого товара</h4>
        <div class="alert-body">
          Предложение с таким товаром не существует. Проверьте
          <b-link class="alert-link" :to="{ name: 'offers' }">
            Список товаров
          </b-link>
          для других товаров.
        </div>
      </b-alert>

      <product-edit-tab-info
        @userDeleted="refetchData"
        :productData="productData"
        class=""
      />

      <b-tabs v-if="productData" pills>
        <!-- Tab: Account -->
        <!-- <b-tab active>
          <template #title>
            <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Информация</span>
          </template>
          
        </b-tab> -->

        <!-- Tab: Social -->
        <!-- <b-tab>
          <template #title>
            <feather-icon icon="Share2Icon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Филиалы</span>
          </template>
          <merchant-edit-tab-services />
        </b-tab> -->

        <!-- Tab: Services -->
        <!-- <b-tab>
          <template #title>
            <feather-icon icon="ShoppingCartIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Товары/услуги</span>
          </template>
          <merchant-edit-tab-services />
        </b-tab> -->

        <!-- Tab: Promocodes -->
        <!-- <b-tab>
          <template #title>
            <feather-icon icon="FileTextIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Купоны/сертификаты</span>
          </template>
          <merchant-edit-tab-services />
        </b-tab> -->
      </b-tabs>
    </component>
  </div>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import ProductEditTabInfo from "./ProductEditTabInfo.vue";
import MerchantEditTabServices from "./MerchantEditTabServices.vue";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ProductEditTabInfo,
    MerchantEditTabServices,
  },
  computed: {},
  data() {
    return {
      productData: {},
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    refetchData() {
      this.getData();
    },
    async getData() {
      const response = await axiosIns
        .get(`v1/product/get-by-id`, {
          params: {
            productId: this.$router.currentRoute.params.id,
          },
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
      this.productData = response.data.data;
    },
  },
};
</script>

<style></style>
